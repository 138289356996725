import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';

@Injectable()
export class InterceptInterceptor implements HttpInterceptor {

  constructor(private router: Router,private helper :HelperService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('boyot-employees/login')){
      return next.handle(request);
    }else{
      return next.handle(request.clone({
        setHeaders:{'Authorization':'Bearer '+localStorage.getItem('token')}})).pipe(
            catchError((error)=>{
              if(error.status == 401){
                this.router.navigate(['auth/login']);
              }else if(error.status == 403){
                this.helper.showToastDanger('Warrning',error.error.message)
              }
              this.helper.hidespinner();
              throw error
            }))
    }
  }
}
