import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionBasedDirective } from './modules/dashboard/directives/permission-based.directive';

@NgModule({
  declarations: [PermissionBasedDirective],
  imports: [CommonModule],
  exports: [PermissionBasedDirective]
})
export class SharedModule { }
