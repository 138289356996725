import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { RolePagesService } from '../../dashboard/_services/role-pages.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url =state.url
   return this.checkUserLogin(route, url);
  }
  checkUserLogin(route: ActivatedRouteSnapshot, url: any){
    const Token = localStorage.getItem('token'),
          userId = localStorage.getItem('userId');
    if (Token && userId) {
      return true;
    }
    this.router.navigate(['auth/login']);
    return false;    
  }
}
