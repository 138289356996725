import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private idsSource = new BehaviorSubject('');
  currentids= this.idsSource.asObservable();
  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService) { }
  public showToastSuccess(title: string, body: string) {
    this.toastr.success(body, title);
  }
  public showToastDanger(title: string, body: string) {
    this.toastr.error(body, title);
  }
  public showToastWarning(title: string, body: string) {
    this.toastr.warning(body, title);
  }
  public showSuccess(title: string, body: string) {
    this.toastr.success(body, title);
  }
  public showspinner() {
    this.spinner.show();
  }
  public hidespinner() {
    this.spinner.hide();
  }
  public clearInput(form:FormGroup,value:string){
    form.get(value).setValue('')
  }
  storeBatchID(id){
    this.idsSource.next(id)
  }
}
