import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HelperService } from "../../services/helper.service";

@Injectable({
  providedIn: "root",
})
export class RolePagesService {
  currentRoleSubject = new BehaviorSubject<any>(undefined);
  currentPageSubject = new BehaviorSubject<any>(undefined);
  currentRole$: Observable<any>;
  currentPage$: Observable<any>;
  constructor(private helper:HelperService,
    ) {
    this.currentRole$ = this.currentRoleSubject.asObservable();
    this.currentPage$ = this.currentPageSubject.asObservable();
  }
  get currentRoleValue(): any {
    return this.currentRoleSubject.value;
  }

  set currentRoleValue(role: any) {
    this.currentRoleSubject.next(role);
  }
  get currentPageValue(): any {
    return this.currentPageSubject.value;
  }

  set currentPageValue(page: any) {
    this.currentPageSubject.next(page);
  }

  checkForAccess(routerLink: any) {
    if (typeof this.currentPageValue == "string") {
      if (JSON.parse(this.currentPageValue).some((r) => r.includes(routerLink)))
        return true;
    } else {
      if (this.currentPageValue.some((r) => r.includes(routerLink)))
        return true;
    }
  }
}
