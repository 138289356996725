import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppService } from '../../services/app.service';
import { RolePagesService } from '../_services/role-pages.service';

@Directive({
  selector: '[appPermissionBased]'
})
export class PermissionBasedDirective {

  @Input() appPermissionBased: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: RolePagesService
  ) {}

  ngOnInit() {
    this.authService.currentPage$.subscribe((userPermissions: string[]) => {
      // const hasAccess = userPermissions.includes(this.appPermissionBased),
      // admin_settings =
      const hasAccess = this.authService.checkForAccess(this.appPermissionBased)
      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

}
